import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import Headermobile from "./Headermobile";
import Footer from "./Footer";
import recharge from "../images/Mainrechargepic.jpeg";

import ReactPlayer from "react-player";
import recharge1 from "../images/RCPTjun-24STV118.jpg";
import recharge2 from "../images/RCPTjun-24STV153.jpg";
import recharge3 from "../images/RCPTjun-24STV199.jpg";
import recharge4 from "../images/RCPTjun-24STV347.jpg";
import recharge5 from "../images/RCPTjun-24STV599.jpg";
import recharge6 from "../images/RCPTjun-24STV997.jpg";
import recharge7 from "../images/RCPTjun-24STV1999.jpg";
import recharge8 from "../images/RCPTjun-24STV2399.jpg";

function Stvoffer() {


  const [adddata, setAdddata] = useState([]);

 
  useEffect(() => {
    fetch(`https://api.rcpt.in/rcpt-api/getaddslinkdetails`, {
      method: 'POST', // or 'POST', depending on your API requirements
  })
  .then(response => response.json())
  .then(data => {
      console.log('Success:', data);
    setAdddata(data)

      // Update the state or handle the response as needed
  })
  .catch((error) => {
      console.error('Error:', error);
  });
  }, [])



const Gamezop = (id) => {
  // Make sure the ID is valid before calling increment
  if (id) {
    increment(id);
  } else {
    console.error("ID is not available");
  }
};
const increment = (id) => {
        fetch(`https://api.rcpt.in/rcpt-api/increasecount?id=${id}`, {
            method: 'GET', // or 'POST', depending on your API requirements
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
          
            // Update the state or handle the response as needed
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };
  return (
    <>
      <Headermobile />
      <div className="">
        {/* <div className="row imagerowbanner">
          <img src={recharge} alt="allrechargeplans" />
        </div>
        <div className="row">
        <div className=" col-sm-12 col-lg-12 col-md-12 d-flex  flex-column align-items-center justify-content-center my-ads-container">
          <p>Advertisement</p>
        <ins
          class="adsbygoogle"
          style={{ display: "inline-block", width: "728px", height: "90px" }}
          data-ad-client="ca-pub-4469777092103047"
          data-ad-slot="7472552521"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        </div>
        </div>
         */}

       
        {/* <div id='gpt-passback-MTF'style={{width:'100%',margin:'10px',textAlign:'center'}} ></div>
                    <div id='gpt-passback-MLB'style={{width:'100%',margin:'10px',textAlign:'center'}} ></div>
                     */}
        <div className="row imagerowbanner">
          <img src={recharge1} alt="plan_151" />
        </div>
       
        {/* <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-4469777092103047"
        data-ad-slot="8197804237"
        data-ad-format="auto"
        data-full-width-responsive="true"/> */}
        {/* <div id="div-gpt-ad-1662350622103-0" style={{width:'100%',margin:'10px',textAlign:'center'}}></div> */}

        <div className="row imagerowbanner mt-3">
          <img src={recharge2} alt="plan_251" />
        </div>
        <div className="row imagerowbanner">
  

        <a href={adddata[0]?.addlink} onClick={() => Gamezop(adddata[0]?.id)}>
      <img src={adddata[0]?.imageurl} alt="mm1" className="img-fluid" />
    </a>
  
</div>
        {/* <div className='my-4' id="gpt-passback-BTF" style={{width:'100%',margin:'10px',textAlign:'center'}}> */}

        {/* </div> */}
        {/* <div className="row">
        <div className=" col-sm-12 col-lg-12 col-md-12 d-flex  flex-column align-items-center justify-content-center my-ads-container">
          <p>Advertisement</p>
        <ins
          class="adsbygoogle"
          style={{ display: "inline-block", width: "728px", height: "90px" }}
          data-ad-client="ca-pub-4469777092103047"
          data-ad-slot="9715572485"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        </div>
        </div> */}
        
        <div className="row imagerowbanner mt-3">
          <img src={recharge3} alt="plans_599" />
        </div>
        {/* <div id="div-gpt-ad-1662350672231-0" style={{width:'100%',margin:'10px',textAlign:'center'}}></div> */}
       
        <div className="row imagerowbanner">
          <img src={recharge4} alt="plan_68" />
        </div>

        {/* <div className="row">
        <div className=" col-sm-12 col-lg-12 col-md-12 d-flex  flex-column align-items-center justify-content-center my-ads-container">
          <p>Advertisement</p>
        <ins
          class="adsbygoogle"
          style={{ display: "inline-block", width: "728px", height: "90px" }}
          data-ad-client="ca-pub-4469777092103047"
          data-ad-slot="9524000798"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        </div>
        </div> */}
        {/* <div className='my-4' id='gpt-passback-GGINT' style={{width:'100%',margin:'10px',textAlign:'center'}}>

</div> */}
 <div className="row imagerowbanner ">

 <a href={adddata[1]?.addlink} onClick={() => Gamezop(adddata[1]?.id)}>
      <img src={adddata[1]?.imageurl} alt="mm1" className="img-fluid" />
    </a>

</div>
        <div className="row imagerowbanner">
          <img src={recharge5} alt="plan_769" />
        </div>

        <div className="row imagerowbanner">
          <img src={recharge6} alt="plan_769" />
        </div>
        <div className="row imagerowbanner mt-3">


        <a href={adddata[2]?.addlink} onClick={() => Gamezop(adddata[2]?.id)}>
      <img src={adddata[2]?.imageurl} alt="mm1" className="img-fluid" />
    </a>

</div>
        <div className="row imagerowbanner">
          <img src={recharge7} alt="plan_769" />
        </div>
        <div className="row imagerowbanner">
          <img src={recharge8} alt="plan_769" />
        </div>
        {/* <div className='my-4' id='gpt-passback-ATF' style={{width:'100%',margin:'10px',textAlign:'center'}}></div> */}
        <div className="row imagerowbanner mt-3">


        <a href={adddata[3]?.addlink} onClick={() => Gamezop(adddata[3]?.id)}>
      <img src={adddata[3]?.imageurl} alt="mm1" className="img-fluid" />
    </a>

</div>
        <div className="d-flex mt-3 mb-3 ml-1 container">
          <div className="specifwidth">
            <Link to="/">
              <button className="newbtn printbutton mt-2">HOME</button>
            </Link>
          </div>
          <div className="specifwidth ">
            <Link to="/Plan">
              <button className="newbtn mt-2 ">Plans</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Stvoffer;
